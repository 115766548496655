import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  MicroCmsLayout,
} from '../../components/_index';

// markup
const SubPage = ({ data }: any) => {
  const news = data.microcmsInfoYrph;

  const result = news.classification.filter(function (value) {
    return value == 'イベント';
  });

  return (
    <Layout>
      <SEO
        title={`${news.title}`}
        description={news.description}
        ogimage={news.eyecatch?.url}
      />
      <div className="l_sect02">
        {result == 'イベント' ? (
          <CBreadCrumb
            data={{
              parent: [{ label: 'イベント', path: '/event/' }],
              current: {
                label: news.title,
              },
            }}
          />
        ) : (
          <>
            <CBreadCrumb
              data={{
                parent: [{ label: 'お知らせ', path: '/information/' }],
                current: {
                  label: news.title,
                },
              }}
            />
          </>
        )}
        <MicroCmsLayout data={news} />
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($id: String!) {
    microcmsInfoYrph(id: { eq: $id }) {
      description
      classification
      date_range {
        start
        end
      }
      eyecatch {
        url
        width
        height
      }
      important
      infoYrphId
      link {
        href
        blank
      }
      pdf {
        url
      }
      newsrelease {
        title
        pdf {
          url
        }
        link {
          href
          blank
        }
      }
      publishedAt
      title
      contents {
        fieldId
        heading_text
        section_title
        section_subtitle
        wysiwyg
        box
        caption
        label
        table
        youtube
        htmlcode
        type
        align
        image {
          url
          width
          height
        }
        images {
          caption
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
        }
        keyvalue {
          fieldId
          keyname
          value
          value2
        }
        list {
          value
        }
        tile {
          image {
            url
            width
            height
          }
          heading_text
          wysiwyg
        }
        card {
          btn {
            fieldId
            label
            link {
              blank
              href
            }
            margin {
              margin
            }
            pdf {
              url
            }
          }
        }
        link {
          href
          blank
        }
        pdf {
          url
        }
        margin {
          margin
        }
        accordion {
          title
          contents {
            fieldId
            heading_text
            wysiwyg
            box
            caption
            label
            table
            youtube
            htmlcode
            type
            align
            image {
              url
              width
              height
            }
            link {
              href
              blank
            }
            pdf {
              url
            }
            margin {
              margin
            }
          }
        }
        left {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          keyvalue {
            fieldId
            keyname
            value
            value2
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
        right {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          keyvalue {
            fieldId
            keyname
            value
            value2
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
      }
    }
  }
`;
